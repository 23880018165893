import React, { useState } from 'react';
import { DateTime } from 'luxon';
import _get from 'lodash/get';
import Form from 'react-bootstrap/esm/Form';

import { VALID_FILE_EXTENSIONS_IMAGE } from '@/utils/constants';

import { REALTIME_COUNTDOWN, REALTIME_COUNTUP } from '@/types/file';
import { fileRealtimeCounters } from '@/features/Schedule/utils';

import '@/features/Schedule/Schedule.scss';
import { IPlaylistFileToAdd } from '@/types/playlist';

interface PendingPlaylistContentListCardProps {
  playlistFile: IPlaylistFileToAdd,
  startDate: DateTime,
  endDate: DateTime,
  onChangePlaylistFile: (value: IPlaylistFileToAdd) => void,
}

const MAX_ID_LENGTH = 5;

const PendingPlaylistContentListCard = (props: PendingPlaylistContentListCardProps) => {
  const { playlistFile, startDate, endDate, onChangePlaylistFile } = props;

  const [validImage, setValidImage] = useState(true);

  const today = DateTime.now();
  const todayISO = today.toFormat("yyyy-MM-dd'T'HH:mm");
  const shortFormatStartDate = startDate.toLocaleString({ month: 'short', day: 'numeric' });
  const shortFormatEndDate = endDate.toLocaleString({ month: 'short', day: 'numeric' });
  const realtimeEntityTypes = playlistFile.file && playlistFile.file.realtimeTypes || [];
  const fileCounters = fileRealtimeCounters(realtimeEntityTypes) || [];
  const fileCountersById: Record<string, {direction: string, entities: string[]}> = {};
  fileCounters.forEach((fileCounter) => {
    const existingFileCounter = fileCountersById[fileCounter.id];
    if (existingFileCounter) {
      fileCountersById[fileCounter.id] = {
        ...existingFileCounter,
        entities: [...existingFileCounter.entities, fileCounter.timeIncrement],
      };
    } else {
      fileCountersById[fileCounter.id] = { direction: fileCounter.direction, entities: [fileCounter.timeIncrement] };
    }
  }, {});

  const validate = (value: string, fileCounterId: string) => {
    if (!value) return false;

    const isCountdown = fileCountersById[fileCounterId].direction === REALTIME_COUNTDOWN;
    const isCountup = fileCountersById[fileCounterId].direction === REALTIME_COUNTUP;
    if (isCountdown && DateTime.fromFormat(value, "yyyy-MM-dd'T'HH:mm") < today) return false;
    if (isCountup && DateTime.fromFormat(value, "yyyy-MM-dd'T'HH:mm") > today) return false;
    return true;
  };

  const renderContentThumbnail = () => {
    return (
      <div className="schedule-thumbnail">
        {(playlistFile.file?.thumbnailUrl
          || (playlistFile.file && VALID_FILE_EXTENSIONS_IMAGE.includes(playlistFile.file.fileType))
          && validImage)
        && (
          <img
            className="align-self-center"
            src={playlistFile.file?.thumbnailUrl || playlistFile.file.url}
            style={{ width: 56, maxHeight: 56 }}
            onError={() => setValidImage(false)}
          />
        )}
      </div>
    );
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center position-relative w-100 px-2 py-3 bg-light-green-1">
        <div className="d-flex flex-grow-1 text-truncate">
          {renderContentThumbnail()}

          <div className="ms-2 text-truncate">
            <strong>{playlistFile.file.name}</strong> <br />
            {`${shortFormatStartDate} - ${shortFormatEndDate}`} <br />
            {Object.keys(fileCountersById).map((fileCounterId) => {
              const counterValue = _get(playlistFile, `realtimeData.counters.${fileCounterId}`, '');
              const isValid = validate(counterValue, fileCounterId);
              return (<div key={fileCounterId}>
                <span className="pe-2">{fileCounterId.slice(0, MAX_ID_LENGTH)} {fileCountersById[fileCounterId].direction}:</span>
                <Form.Control
                  type="datetime-local"
                  className="mx-3 w-75"
                  value={counterValue}
                  min={fileCountersById[fileCounterId].direction === REALTIME_COUNTDOWN ? todayISO : undefined}
                  max={fileCountersById[fileCounterId].direction === REALTIME_COUNTUP ? todayISO : undefined}
                  onChange={(e: any) => onChangePlaylistFile({
                    ...playlistFile,
                    realtimeData: {
                      counters: {
                        ...playlistFile?.realtimeData?.counters,
                        [fileCounterId]: e.target.value,
                      },
                    },
                  })}
                  isInvalid={!isValid}
                  aria-describedby="counterErrorHelpBlock"
                />
                {(!isValid) && (<Form.Text id="counterErrorHelpBlock" className="text-danger">
                  You must set the date to be used on your {fileCountersById[fileCounterId].direction} content.
                </Form.Text>)}
              </div>);
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default PendingPlaylistContentListCard;
