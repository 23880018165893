/* eslint-disable complexity */
import React, { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { useReadContents, useUpdateContents } from '@/hooks/content';
import { useCreatePlaylist, useDuplicatePlaylistAs } from '@/hooks/playlist';
import { useReadOneSign, useReadSigns } from '@/hooks/sign';

import { IPlaylistCreateRequest, IPlaylistFileToAdd } from '@/types/playlist';
import { IContentResponse, IContentUpdateRequest } from '@/types/content';
import { IPlaylistForm } from '@/features/Schedule/types';
import { fileRealtimeCounters } from '@/features/Schedule/utils';

import { EMPTY_RECURRENCE_FORM, DEFAULT_WEIGHT, PLAY_ORDER_OPTIONS, PLAY_STRATEGY_OPTIONS, MOVE_CONTENT_STRATEGY } from '@/features/Schedule/constants';
import { DATETIME_FORMAT } from '@/utils/constants';

import { formatDateRangesToString, today0000, today2359, durationFromNow2359 } from '@/utils/helpers';

import Loader from '@/common/Loader';

import PlaylistForm from './components/PlaylistForm';
import PlaylistContentsCard from './components/PlaylistContentsCard';

interface ContentResponseWithExistingFlag extends IContentResponse {
  existing?: boolean
}

const AddPlaylist = () => {
  const routerNavigate = useNavigate();
  const { signId: signIdRouteParam } = useParams();
  const [searchParams] = useSearchParams();
  const contentMoveStrategy = searchParams.get('moveStrategy');
  const contentIdsString = searchParams.get('contentIds');
  const contentIds: number[] = contentIdsString ? contentIdsString.split(',').map((id) => parseInt(id, 10)) : [];

  const signId = signIdRouteParam ? parseInt(signIdRouteParam, 10) : null;

  const { duplicatePlaylistAs, duplicatedPlaylist } = useDuplicatePlaylistAs();

  const {
    sign: selectedSign,
    readOneSign,
  } = useReadOneSign();

  const {
    createPlaylist,
    createdPlaylist,
    createPlaylistIsLoading,
  } = useCreatePlaylist();

  const {
    contents,
    readContents,
    readContentsIsLoading,
  } = useReadContents();

  const {
    updateContents,
    updatedContents,
    updateContentsIsLoading,
  } = useUpdateContents();

  const {
    readSigns,
    signs,
  } = useReadSigns();

  const today12AM = today0000();
  const today1159PM = today2359();
  const oneMonthFromNow1159PM = durationFromNow2359({ month: 1 });

  const [ errorList, setErrorList ] = useState<string[]>([]);
  const [ isLinked, setIsLinked ] = useState<boolean>(true);
  const [ selectedSignIds, setSelectedSignIds ] = useState<number[]>([]);
  const [ existingContentToAdd, setExistingContentToAdd ] = useState<ContentResponseWithExistingFlag[]>([]);
  const [ filesToAdd, setFilesToAdd ] = useState<IPlaylistFileToAdd[]>([]);
  const [ playlistDateRange, setPlaylistDateRange ] = useState<{start: DateTime, end: DateTime}>({
    start: today12AM,
    end: oneMonthFromNow1159PM,
  });

  const recurrence = EMPTY_RECURRENCE_FORM;

  // Set contents if user navigated from a selected content
  useEffect(() => {
    if (contentIdsString && !contents) {
      readContents({ ids: contentIds });
      return;
    }
    if (!contentIdsString && !contents && signId) {
      readContents({ signId });
      return;
    }
  }, [contentIdsString]);

  useEffect(() => {
    if (contents && contentIdsString) setExistingContentToAdd(contents);
  }, [contents]);

  useEffect(() => {
    if (!createdPlaylist) return;

    if (!isLinked && selectedSignIds.length > 1) {
      selectedSignIds.forEach((selectedSignId, idx) => {
        // first sign was the original playlist created
        const signIds = idx > 0 ? [selectedSignId] : [];

        duplicatePlaylistAs(
          createdPlaylist.name,
          createdPlaylist,
          signIds,
        );
      });
    }
  }, [createdPlaylist]);

  const removeMovedContentFromSign = () => {
    const contentsToUpdate = contents?.reduce((acc: IContentUpdateRequest[], currContent) => {
      if (contentIds.includes(currContent.id)) {
        const existingPlaylistIds = currContent.playlists.map((contentPlaylist) => contentPlaylist.id);
        const playlistIds = createdPlaylist ? [...existingPlaylistIds, createdPlaylist.id] : existingPlaylistIds;
        const contentSignIds = currContent.signs.map((contentSign) => contentSign.id);
        const updatedContent: IContentUpdateRequest = {
          id: currContent.id,
          signIds: contentSignIds.filter((contentSignId) => contentSignId !== signId),
          playlistIds,
        };
        return [...acc, updatedContent];
      }

      return acc;
    }, []);

    if (contentsToUpdate) updateContents(contentsToUpdate);
  };

  useEffect(() => {
    if (!createdPlaylist) return;

    const waitForContentUpdates = contentMoveStrategy === MOVE_CONTENT_STRATEGY && !updatedContents;
    if (waitForContentUpdates) {
      removeMovedContentFromSign();
    }

    const shouldNavigateToSignPage = ((isLinked && createdPlaylist) || (!isLinked && duplicatedPlaylist)) && !waitForContentUpdates;

    if (shouldNavigateToSignPage) {
      routerNavigate(`/schedule/signs/${signId}`);
    }
  }, [createdPlaylist, duplicatedPlaylist, updatedContents]);

  useEffect(() => {
    if (signId) readOneSign(signId);

    readSigns();
  }, []);

  const playlistItemInitialFormValues: IPlaylistForm = {
    name: '',
    startDate: today12AM,
    endDate: oneMonthFromNow1159PM,
    frequencyWeight: DEFAULT_WEIGHT,
    isPaused: false,
    contentIds: contentIds,
    isLinked: true,
    signIds: selectedSign ? [selectedSign.id] : [],
    signs: selectedSign ? [selectedSign] : [],
    contents: contents || [],
    playOrder: PLAY_ORDER_OPTIONS[0],
    playStrategy: PLAY_STRATEGY_OPTIONS[0],
    recurrence: {
      ...recurrence,
      byDay: [],
      startTime: today12AM,
      endTime: today1159PM,
      rDate: [],
      exDate: [],
      exTime: [],
    },
  };

  const onNewContentFilesSelected = (files: IPlaylistFileToAdd[]) => {
    setFilesToAdd([...filesToAdd, ...files]);
  };

  const onPlaylistFormChange = (playlistForm: IPlaylistForm) => {
    setPlaylistDateRange({
      start: playlistForm.startDate,
      end: playlistForm.endDate,
    });
  };

  const updateFileToAdd = (fileToUpdate: IPlaylistFileToAdd) => {
    const fileToUpdateIndex = filesToAdd.findIndex((file) => file.file.id === fileToUpdate.file.id);
    const updatedFilesToAdd = filesToAdd.map((file, idx) => {
      if (fileToUpdateIndex === idx) {
        return fileToUpdate;
      }
      return file;
    });
    setFilesToAdd(updatedFilesToAdd);
  };

  const validate = (values: IPlaylistForm) => {
    const counterMissingDate = filesToAdd.some((playlistFile) => {
      const realtimeCounters = fileRealtimeCounters(playlistFile.file.realtimeTypes || []);
      return realtimeCounters.length > 0 && !playlistFile.realtimeData;
    });
    if (counterMissingDate) {
      setErrorList([...errorList, 'Must set counter dates for every countdown / countup content.']);
      return false;
    }

    if (values.name.length < 1) {
      setErrorList([...errorList, 'Must set playlist name.']);
      return false;
    }

    setErrorList([]);
    return true;
  };

  const createPlaylistItem = (values: IPlaylistForm) => {
    const isValid = validate(values);

    if (!isValid) return null;
    setIsLinked(values.isLinked || false);

    const formSignIds = values.signs.map((sign) => sign.id);
    setSelectedSignIds(formSignIds);

    const uniqueAddedContentIds = existingContentToAdd.reduce((acc: number[], contentToAdd) => {
      if (!contentIds.includes(contentToAdd.id)) return [contentToAdd.id, ...acc];
      return acc;
    }, []);

    const playlistPostRequestData: IPlaylistCreateRequest = {
      name: values.name || '',
      startDate: values.startDate ? values.startDate.toFormat(DATETIME_FORMAT) : today12AM,
      endDate: values.endDate ? values.endDate.toFormat(DATETIME_FORMAT) : oneMonthFromNow1159PM,
      frequencyWeight: values.frequencyWeight || DEFAULT_WEIGHT,
      isPaused: values.isPaused || false,
      files: filesToAdd.map((playlistFile) => ({ id: playlistFile.file.id, realtimeData: playlistFile.realtimeData })),
      contentIds: [...contentIds, ...uniqueAddedContentIds],
      signIds: formSignIds,
      playOrder: values.playOrder || PLAY_ORDER_OPTIONS[0],
      playStrategy: values.playStrategy || PLAY_STRATEGY_OPTIONS[0],
      recurrence: {
        rDate: values.recurrence.rDate ? formatDateRangesToString(values.recurrence.rDate) : null,
        exDate: values.recurrence.exDate ? formatDateRangesToString(values.recurrence.exDate) : null,
        exTime: values.recurrence.exTime ? formatDateRangesToString(values.recurrence.exTime) : null,
        freq: values.recurrence.freq,
        byDay: values.recurrence.byDay.map((day) => ({
          dayOfWeek: day.dayOfWeek,
          startTime: day.startTime.toFormat(DATETIME_FORMAT),
          endTime: day.endTime.toFormat(DATETIME_FORMAT),
        })),
        byWeek: values.recurrence.byWeek,
        byMonth: values.recurrence.byMonth,
        byYear: values.recurrence.byYear,
        startTime: values.recurrence.startTime.toFormat(DATETIME_FORMAT),
        endTime: values.recurrence.endTime.toFormat(DATETIME_FORMAT),
      },
    };

    // If playlist is unlinked, create a duplicate of the playlist for each sign
    if (!values.isLinked && formSignIds.length > 1) {
      formSignIds.forEach((formSignId) => {
        createPlaylist({ ...playlistPostRequestData, signIds: [formSignId] });
      });
      return null;
    }

    return createPlaylist(playlistPostRequestData);
  };

  // need to wait for sign to load so we can properly set
  // initial signs in form
  if (!selectedSign) {
    return (
      <Loader size="2x" />
    );
  }

  const isLoading = readContentsIsLoading || createPlaylistIsLoading || updateContentsIsLoading;

  return (<>
    <PlaylistForm
      formValues={playlistItemInitialFormValues}
      playlistContents={existingContentToAdd}
      onChange={onPlaylistFormChange}
      onSubmit={createPlaylistItem}
      slots={{
        playlistContentsCard: (<PlaylistContentsCard
          sign={selectedSign}
          playlistContents={existingContentToAdd}
          pendingPlaylistContents={filesToAdd}
          updateFileToAdd={updateFileToAdd}
          playlistDateRange={playlistDateRange}
          refreshContentList={() => null}
          addNewContent={onNewContentFilesSelected}
          userSigns={signs}
        />),
      }}
      isLoading={isLoading}
      userSigns={signs}
      errorList={errorList}
    />
  </>);
};

export default AddPlaylist;
