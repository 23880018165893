import React from 'react';
import { Formik, FormikProps } from 'formik';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Alert from 'react-bootstrap/esm/Alert';
import Form from 'react-bootstrap/esm/Form';
import Button from 'react-bootstrap/esm/Button';

import { IContentResponse } from '@/types/content';
import { IPlaylistForm } from '@/features/Schedule/types';
import { ENTER_KEY_CODE } from '@/features/Schedule/constants';

import FormObserver from '@/common/FormObserver';
import CardDetailsSignBadges from '@/features/Schedule/components/CardDetailsSignBadges';
import CardDetailsDateRange from '@/features/Schedule/components/CardDetailsDateRange';
import CardDetailsFrequencyWeight from '@/features/Schedule/components/CardDetailsFrequencyWeight';
import CardDetailsPlaybackActivity from '@/features/Schedule/components/CardDetailsPlaybackActivity';
import { ISignResponse } from '@/types/sign';

interface PlaylistFormProps {
  formValues: IPlaylistForm,
  playlistContents: IContentResponse[],
  onChange: (playlistFormValues: IPlaylistForm) => void,
  onSubmit: (playlistFormValues: IPlaylistForm) => void,
  slots: {
    playlistContentsCard: React.ReactNode,
    playlistDeleteButton?: React.ReactNode,
  }
  isLoading?: boolean;
  userSigns: ISignResponse[],
  errorList: string[]
}

const PlaylistForm = ({
  formValues,
  playlistContents,
  onChange,
  onSubmit,
  slots,
  isLoading = false,
  userSigns,
  errorList,
}: PlaylistFormProps) => {
  const routerNavigate = useNavigate();

  const playlistContentFiles = playlistContents.map((content) => content.file);

  return (
    <Formik
      initialValues={formValues}
      onSubmit={onSubmit}
    >
      {({
        values,
        setFieldValue,
        handleSubmit,
        handleBlur,
        handleChange,
      }: FormikProps<IPlaylistForm>) => {
        return (
          <Form
            noValidate
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <FormObserver<IPlaylistForm> onChange={onChange} />
            <section className="bg-white shadow-sm p-3">
              {/* Name */}
              <Form.Control
                className="fw-bold mb-3"
                type="text"
                id="name"
                placeholder="Enter Playlist Name..."
                value={values.name}
                onKeyPress={(e: any) => {
                  if (e.charCode === ENTER_KEY_CODE) {
                    e.preventDefault();
                    document.getElementById('playlist-name')?.blur();
                  }
                }}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <CardDetailsSignBadges
                selectedSigns={values.signs}
                userSigns={userSigns}
                onSubmitSelectedSigns={(signs) => setFieldValue('signs', signs)}
                files={playlistContentFiles}
              />
            </section>

            <section className="bg-white shadow-sm p-3 mt-2">
              <CardDetailsDateRange
                values={values}
                setFieldValue={setFieldValue}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </section>

            {/* Playlist Contents Card, not a part of the form data but needs to be shown here */}
            {slots.playlistContentsCard}

            <section className="mt-2">
              <CardDetailsFrequencyWeight
                item={values}
                setFieldValue={setFieldValue}
                disabledFrequencyWeight={false}
              />
            </section>

            <section className="mt-2">
              <CardDetailsPlaybackActivity
                item={values}
                setFieldValue={setFieldValue}
              />
            </section>

            {/* Delete Playlist Button, not a part of the form data but needs to be shown here */}
            {slots.playlistDeleteButton}

            <section
              className="mt-3 p-3 bg-white shadow-sm d-flex justify-content-end border-top sticky-bottom"
            >
              {errorList.length > 0 && (<Alert variant="danger" className="p-2 my-0 mx-2">{errorList[0]}<b>{errorList.length > 1 && ` +${errorList.length - 1} more`}</b></Alert>)}
              <Button
                variant="light"
                onClick={() => routerNavigate(-1)}
                disabled={isLoading}
              >
                Cancel
              </Button>
              <Button
                className="ms-2"
                type="submit"
                variant="primary"
                disabled={isLoading}
              >
                {(isLoading)
                  ? (<FontAwesomeIcon
                    icon="circle-notch"
                    spin
                  />)
                  : 'Submit'
                }
              </Button>
            </section>
          </Form>
        );
      }}
    </Formik>
  );
};

export default PlaylistForm;
