import React from 'react';
import Card from 'react-bootstrap/esm/Card';
import Form from 'react-bootstrap/esm/Form';
import { Field } from 'formik';

import { IContentForm } from '@/features/Schedule/types';
import { IFileResponse, REALTIME_COUNTDOWN, REALTIME_COUNTUP } from '@/types/file';
import { fileRealtimeCounters } from '@/features/Schedule/utils';

interface CardDetailsCounterDateProps {
  values: IContentForm,
  contentFile: IFileResponse | null,
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  errors?: any,
}

const CardDetailsRealtimeData = ({ values, contentFile, setFieldValue, errors }: CardDetailsCounterDateProps) => {
  const realtimeEntityTypes = contentFile && contentFile.realtimeTypes || [];
  const fileCounters = fileRealtimeCounters(realtimeEntityTypes) || [];
  const fileCountersById: Record<string, {direction: string, entities: string[]}> = {};
  fileCounters.forEach((fileCounter) => {
    const existingFileCounter = fileCountersById[fileCounter.id];
    if (existingFileCounter) {
      fileCountersById[fileCounter.id] = {
        ...existingFileCounter,
        entities: [...existingFileCounter.entities, fileCounter.timeIncrement],
      };
    } else {
      fileCountersById[fileCounter.id] = { direction: fileCounter.direction, entities: [fileCounter.timeIncrement] };
    }
  });
  const showCounterSetupSection = Object.keys(fileCountersById).length > 0;
  const showRealtimeDataSection = showCounterSetupSection;

  const onCounterChange = (value: string, counterId: string) => {
    setFieldValue('realtimeData', { counters: {
      ...values.realtimeData && values.realtimeData.counters,
      [counterId]: value,
    } });
  };

  const renderCounterSetup = () => {
    const todayISO = new Date().toISOString().slice(0, new Date().toISOString().lastIndexOf(':'));

    const MAX_ID_INDEX = 5;

    return (
      <>
        <Card.Subtitle className="mt-3 ms-2">Counter Date</Card.Subtitle>
        {Object.keys(fileCountersById).map((counterId) => {
          const counterValue = values.realtimeData && values.realtimeData.counters && values.realtimeData.counters[counterId];
          return (<div key={counterId} className="m-3 mt-0">
            <sub>{fileCountersById[counterId].direction} {counterId.slice(0, MAX_ID_INDEX)}</sub>
            <Form.Group>
              <Field
                component={Form.Control}
                type="datetime-local"
                className="mx-3 w-75"
                value={counterValue}
                min={fileCountersById[counterId].direction === REALTIME_COUNTDOWN ? todayISO : null}
                max={fileCountersById[counterId].direction === REALTIME_COUNTUP ? todayISO : null}
                onChange={(e: any) => onCounterChange(e.target.value, counterId)}
                isInvalid={errors?.realtimeData?.counters[counterId]}
              />
              <Form.Control.Feedback type="invalid">
                {errors && errors.realtimeData && errors.realtimeData.counters[counterId]}
              </Form.Control.Feedback>
            </Form.Group>
          </div>);
        },
        )}
      </>
    );
  };

  return (
    <>
      {showRealtimeDataSection && (<Card className="bg-white shadow-sm">
        <Card.Body>
          <Card.Title>Configure Feed Data</Card.Title>
          {showCounterSetupSection && renderCounterSetup()}
        </Card.Body>
      </Card>)}
    </>
  );
};

export default CardDetailsRealtimeData;
