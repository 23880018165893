/* eslint-disable no-process-env */
import React from 'react';
import ReactDOM from 'react-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import { BrowserRouter } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCircle,
  faClipboard,
  faTrashCan,
} from '@fortawesome/free-regular-svg-icons';
import {
  faArrowDown,
  faArrowDownAZ,
  faArrowDownShortWide,
  faArrowDownWideShort,
  faArrowDownZA,
  faArrowRightFromBracket,
  faArrowRightToBracket,
  faArrowRotateRight,
  faArrowsRotate,
  faArrowUp,
  faBackward,
  faBars,
  faBuilding,
  faCaretDown,
  faCaretRight,
  faChevronLeft,
  faChevronRight,
  faCircleCheck,
  faCircleNotch,
  faCircleXmark,
  faClock,
  faClone,
  faCopy,
  faDownload,
  faEllipsis,
  faEllipsisV,
  faEllipsisVertical,
  faExclamationTriangle,
  faFileVideo,
  faFloppyDisk,
  faFolder,
  faFolderPlus,
  faForward,
  faHome,
  faHourglass,
  faICursor,
  faInfoCircle,
  faKeyboard,
  faList,
  faListOl,
  faListUl,
  faLocationDot,
  faPause,
  faPencil,
  faPhotoFilm,
  faPlay,
  faPlus,
  faRectangleList,
  faRefresh,
  faSearch,
  faShuffle,
  faSort,
  faTableCellsLarge,
  faTemperatureThreeQuarters,
  faTrash,
  faUpload,
  faUser,
  faWifi,
  faX,
  faSun,
} from '@fortawesome/free-solid-svg-icons';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import UserProvider from '@/context/myUser/myUserProvider';

library.add(

  // far
  faCircle,
  faClipboard,
  faTrashCan,

  // fas
  faArrowDown,
  faArrowDownAZ,
  faArrowDownShortWide,
  faArrowDownWideShort,
  faArrowDownZA,
  faArrowRightFromBracket,
  faArrowRightToBracket,
  faArrowRotateRight,
  faArrowsRotate,
  faArrowUp,
  faBackward,
  faBars,
  faBuilding,
  faCaretDown,
  faCaretRight,
  faChevronLeft,
  faChevronRight,
  faCircleCheck,
  faCircleNotch,
  faCircleXmark,
  faClock,
  faClone,
  faCopy,
  faDownload,
  faEllipsis,
  faEllipsisV,
  faEllipsisVertical,
  faExclamationTriangle,
  faFileVideo,
  faFloppyDisk,
  faFolder,
  faFolderPlus,
  faForward,
  faHome,
  faHourglass,
  faICursor,
  faInfoCircle,
  faKeyboard,
  faList,
  faListOl,
  faListUl,
  faLocationDot,
  faPause,
  faPencil,
  faPhotoFilm,
  faPlay,
  faPlus,
  faRectangleList,
  faRefresh,
  faSearch,
  faShuffle,
  faSort,
  faTableCellsLarge,
  faTemperatureThreeQuarters,
  faTrash,
  faUpload,
  faUser,
  faWifi,
  faX,
  faSun,
);

import '@/styles/index.scss';
import './api/appAxios';

import App from './App';

const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID || '';
const domain = process.env.REACT_APP_AUTH0_ISSUER || '';
const audience = process.env.REACT_APP_AUTH0_AUDIENCE || '';
const redirectUri = `${process.env.REACT_APP_APP_URL}/home`;
const packageVersion = process.env.PACKAGE_VERSION;
const auth0CookieDomain = process.env.REACT_APP_AUTH0_COOKIE_DOMAIN;

// eslint-disable-next-line no-console
console.log(`%cScheduler Software Version: ${packageVersion}`, 'background: #3d3d3d; color: #fff; padding: 4px 8px;');

// fade splash screen out
const splashScreen = document.getElementById('splash-screen');
if (splashScreen) {
  splashScreen.classList.add('fade-out');
}

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Auth0Provider
        domain={domain}
        clientId={clientId}
        authorizationParams={{
          audience,
          'redirect_uri': redirectUri,
        }}
        useRefreshTokens={true}
        useRefreshTokensFallback={true}
        cookieDomain={auth0CookieDomain}
      >
        <UserProvider>
          <DndProvider backend={HTML5Backend}>
            <App />
          </DndProvider>
        </UserProvider>
      </Auth0Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
);
