/* eslint-disable complexity */
import React, { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import _set from 'lodash/set';
import _uniq from 'lodash/uniq';

import Alert from 'react-bootstrap/esm/Alert';
import Button from 'react-bootstrap/esm/Button';
import Form from 'react-bootstrap/esm/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useParams, useNavigate } from 'react-router-dom';
import { Formik, FormikProps } from 'formik';
import * as Yup from 'yup';

import { IContentCreateRequest, ContentRealtimeData } from '@/types/content';
import { IFileResponse, REALTIME_COUNTDOWN, REALTIME_COUNTUP } from '@/types/file';

import Loader  from '@/common/Loader';
import FileWithPreview from '@/common/FileWithPreview/FileWithPreview';
import FileDetailsBadges from '@/common/FileDetailsBadges';

import { useToggle } from '@/hooks';
import { useCreateContent, useReadOneContent, useUpdateOneContent, useDeleteContents } from '@/hooks/content';
import { useReadSigns } from '@/hooks/sign';

import { DATETIME_FORMAT, VALID_FILE_EXTENSIONS_IMAGE } from '@/utils/constants';
import {  today0000, durationFromNow2359, formatDateRangesToString, formatDateRangesToDateTime, formatTimeRangesToDateTime } from '@/utils/helpers';

import { IContentForm, IContentErrors } from '@/features/Schedule/types';
import { userHasAccessToSigns, fileRealtimeCounters } from '@/features/Schedule/utils';
import { DEFAULT_CONTENT_DURATION_IN_MILLISECONDS, DEFAULT_WEIGHT, EMPTY_RECURRENCE_FORM, ENTER_KEY_CODE } from '@/features/Schedule/constants';

import CardDetailsSignBadges from './CardDetailsSignBadges';
import CardDetailsDateRange from './CardDetailsDateRange';
import CardDetailsRealtimeData from './CardDetailsRealtimeData';
import CardDetailsDuration from './CardDetailsDuration';
import CardDetailsFrequencyWeight from './CardDetailsFrequencyWeight';
import CardDetailsPlaybackActivity from './CardDetailsPlaybackActivity';
import ConfirmDeleteModal from './ConfirmDeleteModal';
import SwapContentModal from './SwapContentModal';

const EditContent = () => {
  const { signId: signIdRouteParam, playlistId: playlistIdRouteParam, contentId: contentIdRouteParam } = useParams();
  const routerNavigate = useNavigate();

  const signId = signIdRouteParam ? parseInt(signIdRouteParam, 10) : null;
  const playlistId = playlistIdRouteParam ? parseInt(playlistIdRouteParam, 10) : null;
  const contentId = contentIdRouteParam ? parseInt(contentIdRouteParam, 10) : null;

  const [selectedFile, setSelectedFile] = useState<IFileResponse | null>(null);
  const [errorList, setErrorList] = useState<string[]>([]);

  const {
    createContent,
    createContentIsLoading,
  } = useCreateContent();

  const {
    readOneContent,
    content: contentItem,
    readOneContentIsLoading,
  } = useReadOneContent();

  const {
    updateOneContent,
    updatedContent,
    updateOneContentIsLoading,
  } = useUpdateOneContent();

  const {
    deleteContents,
    deletedContents,
  } = useDeleteContents();

  const {
    readSigns,
    signs,
  } = useReadSigns();

  const goBack = () => {
    routerNavigate(-1);
  };

  // get api data
  useEffect(() => {
    if (contentId) readOneContent(contentId);

    readSigns();
  }, []);

  useEffect(() => {
    if (!updatedContent) return;

    goBack();
    setSelectedFile(null);
  }, [updatedContent]);

  useEffect(() => {
    if (!deletedContents) return;
    goBack();
  }, [deletedContents]);

  const onCancel = (() => {
    setSelectedFile(null);
    goBack();
  });

  const { show: showConfirmDeleteModal, hide: hideConfirmDeleteModal, isVisible: isConfirmDeleteModalVisible } = useToggle();
  const { show: showSwapContentModal, hide: hideSwapContentModal, isVisible: isSwapContentModalVisible } = useToggle();

  const today12AM = today0000();

  const oneMonthFromNow1159PM = durationFromNow2359({ month: 1 });

  const recurrence = contentItem?.recurrence || EMPTY_RECURRENCE_FORM;

  const initSigns = contentItem
    ? [...contentItem.displaySigns]
    : [];

  const fileCounters = fileRealtimeCounters(selectedFile?.realtimeTypes || contentItem?.file?.realtimeTypes);

  const initializeRealtimeDataValues = () => {
    if (!fileCounters) return null;

    const realtimeDataCounters: ContentRealtimeData = { counters: {} };
    fileCounters.forEach((fileCounter) => {
      realtimeDataCounters.counters[fileCounter.id] = '';
    });
    return realtimeDataCounters;
  };

  const contentItemInitialFormValues: IContentForm = {
    name: contentItem?.name || '',
    fileId: contentItem?.file?.id || 1,
    startDate: contentItem?.startDatetime ? DateTime.fromFormat(contentItem?.startDatetime, DATETIME_FORMAT) : today12AM,
    endDate: contentItem?.endDatetime ? DateTime.fromFormat(contentItem?.endDatetime, DATETIME_FORMAT) : oneMonthFromNow1159PM,
    durationInMilliseconds: contentItem?.durationInMilliseconds || DEFAULT_CONTENT_DURATION_IN_MILLISECONDS,
    frequencyWeight: contentItem?.frequencyWeight || contentItem?.frequencyWeight === 0 ? contentItem?.frequencyWeight : DEFAULT_WEIGHT,
    isPaused: contentItem?.isPaused || false,
    realtimeData: contentItem?.realtimeData || initializeRealtimeDataValues(),
    signIds: initSigns.map((s) => s.id),
    signs: initSigns,
    displaySigns: contentItem?.displaySigns || [],
    playlistIds: contentItem?.playlists.map((p) => p.id) || [],
    playlists: contentItem?.playlists || [],
    isLinked: true,
    recurrence: {
      ...recurrence,
      byDay: recurrence.byDay?.map((day) => ({
        dayOfWeek: day.dayOfWeek,
        startTime: DateTime.fromFormat(day.startTime, DATETIME_FORMAT),
        endTime: DateTime.fromFormat(day.endTime, DATETIME_FORMAT),
      })) || [],
      startTime: recurrence.startTime ? DateTime.fromFormat(recurrence.startTime, DATETIME_FORMAT) : today12AM,
      endTime: recurrence.endTime ? DateTime.fromFormat(recurrence.endTime, DATETIME_FORMAT) : oneMonthFromNow1159PM,
      rDate: recurrence.rDate ? formatDateRangesToDateTime(recurrence.rDate) : null,
      exDate: recurrence.exDate ? formatDateRangesToDateTime(recurrence.exDate) : null,
      exTime: recurrence.exTime ? formatTimeRangesToDateTime(recurrence.exTime) : null,
    },
  };

  const updateContentItem = (values: IContentForm) => {
    if (!contentItem) return;

    const isLinked = values.isLinked;

    const existingTopLevelSignIds = contentItem.signs.map((sign) => sign.id);

    // determine which signs content belongs to (through a playlist)
    const existingPlaylistSignIds = contentItem.displaySigns
      .filter((sign) => !existingTopLevelSignIds.includes(sign.id))
      .map((sign) => sign.id);

    // get map where key is playlist id and value is array of sign ids playlist belongs to
    const existingPlaylistIdToSignIdMap = contentItem.playlists.reduce((acc, playlist) => {
      acc[playlist.id] = playlist.signs.map((sign) => sign.id);

      return acc;
    }, {} as Record<string, number[]>);

    const newTopLevelSignIds = values.signs
      .filter((sign) => !existingPlaylistSignIds.includes(sign.id))
      .map((sign) => sign.id);
    const newPlaylistIds = Object.keys(existingPlaylistIdToSignIdMap)
      .reduce((acc, playlistIdKey) => {
        const exisitingPlaylistSignIds = existingPlaylistIdToSignIdMap[playlistIdKey];

        let keepPlaylist = false;

        // if playlist is attached to no signs, keep playlist
        // this means the playlist is saved in playlist list page
        // and not currently attached to sign
        if (exisitingPlaylistSignIds.length === 0) {
          keepPlaylist = true;
        }

        // if any existing playlist signId is within new sign ids,
        // then keep playlist
        const everyPlaylistSignIdIncluded = existingPlaylistSignIds.every((playlistSignId) => values.signs.map((sign) => sign.id).includes(playlistSignId));
        if (everyPlaylistSignIdIncluded) {
          keepPlaylist = true;
        }

        if (keepPlaylist) {
          acc.push(Number(playlistIdKey));
        }

        return acc;
      }, [] as number[]);

    const existingFile = contentItem.file ? contentItem.file : null;
    const contentPutRequestConfig = {
      id: contentItem.id || 1,
      file: selectedFile || existingFile,
      name: values.name,
      fileId: selectedFile?.id || existingFile?.id,
      startDate: values.startDate.toFormat(DATETIME_FORMAT),
      endDate: values.endDate.toFormat(DATETIME_FORMAT),
      durationInMilliseconds: values.durationInMilliseconds,
      frequencyWeight: values.frequencyWeight,
      isPaused: values.isPaused,
      realtimeData: values.realtimeData,
      playlistIds: newPlaylistIds,
      signIds: newTopLevelSignIds,
      playlists: values.playlists,
      recurrence: {
        rDate: values.recurrence.rDate ? formatDateRangesToString(values.recurrence.rDate) : null,
        exDate: values.recurrence.exDate ? formatDateRangesToString(values.recurrence.exDate) : null,
        exTime: values.recurrence.exTime ? formatDateRangesToString(values.recurrence.exTime) : null,
        freq: values.recurrence.freq,
        byDay: values.recurrence.byDay.map((day) => ({
          dayOfWeek: day.dayOfWeek,
          startTime: day.startTime.toFormat(DATETIME_FORMAT),
          endTime: day.endTime.toFormat(DATETIME_FORMAT),
        })),
        byWeek: values.recurrence.byWeek,
        byMonth: values.recurrence.byMonth,
        byYear: values.recurrence.byYear,
        startTime: values.recurrence.startTime.toFormat(DATETIME_FORMAT),
        endTime: values.recurrence.endTime.toFormat(DATETIME_FORMAT),
      },
    };

    // If Content is Unlinked, create copies on each of the other signs and save the current sign
    if (!isLinked && signId) {
      const getPlaylistIdsBySignId = (signIdVal: number) => {
        return contentItem.playlists.reduce((playlistIds: number[], playlist) => {
          const playlistInSign = playlist.signs.find((sign) => sign.id === signIdVal);
          if (playlistInSign) return [playlist.id, ...playlistIds];
          return [...playlistIds];
        }, []);
      };
      const unlinkedContents = values.signs.filter((i) => i.id !== signId).map((j) => {
        const playlistIds = getPlaylistIdsBySignId(j.id); // make sure the copied version is added to playlist if it came from a playlist
        const signIds = playlistIds.length ? [] : [j.id]; // only add to top level if it's not associated with a playlist
        return {
          ...contentPutRequestConfig,
          fileId: contentPutRequestConfig.fileId || 1,
          signIds,
          playlistIds,
        };
      });

      unlinkedContents.forEach((content: IContentCreateRequest) => {
        createContent(content);
      });
      const playlistIds = getPlaylistIdsBySignId(signId);
      updateOneContent({ ...contentPutRequestConfig, signIds: [signId], playlistIds });
    } else {
      // If Content is Linked, attach signs to content
      updateOneContent(contentPutRequestConfig);
    }
  };

  const onDeleteContents = (deleteAll: boolean) => {
    if (deleteAll && contentItem) {
      deleteContents([contentItem.id]);
    } else if (!deleteAll && contentItem) {
      const contentItemPlaylistIds = contentItem.playlists.map((contentPlaylist) => contentPlaylist.id);
      const contentItemSignIds = contentItem.signs.map((contentSign) => contentSign.id);
      let playlistIds = contentItemPlaylistIds;
      let signIds = contentItemSignIds.filter((contentSignId) => contentSignId !== Number(signId));

      // if content is inside playlist remove content from playlist
      // and leave signIds alone (top level content)
      if (playlistId) {
        playlistIds = contentItemPlaylistIds.filter((contentPlaylistId) => contentPlaylistId !== Number(playlistId));
        signIds = contentItemSignIds;
      }

      updateOneContent({
        id: contentItem.id,
        playlistIds,
        signIds,
      });
    }
  };

  const onSwapContent = (newFiles: IFileResponse[]) => {
    const [newFile] = newFiles;
    setSelectedFile(newFile);
    hideSwapContentModal();
  };

  const validate = (values: IContentForm) => {
    const errors: IContentErrors = {};
    const errorTexts: string[] = [];

    const addError = (errorKey: string, errorValue: string) => {
      _set(errors, errorKey, errorValue);
      errorTexts.push(errorValue);
    };

    if (values.startDate > values.endDate) {
      addError('endDate', 'Start date must be before end date.');
      addError('startDate', 'Start date must be before end date.');
    }

    if (values.recurrence.startTime.toMillis() >= values.recurrence.endTime.toMillis()) {
      if (!errors.recurrence) errors.recurrence = {};
      addError('recurrence.startTime', 'Daily Start must be before Daily End.');
      addError('recurrence.endTime', 'Daily End must be after Daily Start.');
    }

    if (values.recurrence.exTime && !!values.recurrence.exTime.find(([start, end]) => {
      return start > end;
    })) {
      if (!errors.recurrence) errors.recurrence = {};
      addError('recurrence.exTime', 'Start exclusion time must be before end exclusion time.');
    }

    if (values.recurrence.exDate && !!values.recurrence.exDate.find(([start, end]) => {
      return start > end;
    })) {
      if (!errors.recurrence) errors.recurrence = {};
      addError('recurrence.exDate', 'Start exclusion date must be before end exclusion date.');
    }

    // If user has counters in the template, make sure they've set the dates
    if (fileCounters) {
      fileCounters.forEach((fileCounter) => {
        const today = new Date();
        const fileCounterDateSet = values.realtimeData && new Date(values.realtimeData.counters[fileCounter.id]);
        const invalidDate = values.realtimeData && !values.realtimeData.counters[fileCounter.id];
        const invalidPastDate = fileCounter.direction === REALTIME_COUNTDOWN && fileCounterDateSet && fileCounterDateSet.getTime() < today.getTime();
        const invalidFutureDate = fileCounter.direction === REALTIME_COUNTUP && fileCounterDateSet && fileCounterDateSet.getTime() > today.getTime();

        if (invalidDate || invalidPastDate || invalidFutureDate) {
          if (!errors.realtimeData || !errors.realtimeData.counters) errors.realtimeData = { counters: {} };
          addError(`realtimeData.counters.${fileCounter.id}`, `Must set valid date for ${fileCounter.direction}.`);
        }
      });
    }

    if (typeof values.durationInMilliseconds === 'number' && values.durationInMilliseconds <= 0) {
      addError('durationInMilliseconds', 'Duration must be greater than 0.');
    }

    setErrorList(_uniq(errorTexts));
    return errors;
  };

  const schema = Yup.object().shape({
    name: Yup.string().required('Please enter content name'),
  });

  if (!contentId) {
    return (<div>No Content.</div>);
  }

  if (readOneContentIsLoading) return (<Loader size="2x" />);

  if (!contentItem) {
    return null;
  }

  const isImageFileType = contentItem
    && contentItem.file
    && contentItem.file.fileType
    && VALID_FILE_EXTENSIONS_IMAGE.includes(contentItem.file.fileType.toLowerCase());

  return (<>
    <Formik
      initialValues={contentItemInitialFormValues}
      onSubmit={(contentFormValues: IContentForm) => updateContentItem(contentFormValues)}
      validationSchema={schema}
      validate={validate}
      disabled={updateOneContentIsLoading || createContentIsLoading}
    >
      {({
        values,
        handleBlur,
        handleChange,
        setFieldValue,
        touched,
        errors,
        handleSubmit,
      }: FormikProps<IContentForm>) => {
        const contentFile = selectedFile || contentItem.file;

        const durationInMilliseconds = values.durationInMilliseconds || 0;

        return (
          <Form noValidate onSubmit={handleSubmit}>
            <section className="bg-white shadow-sm p-3">
              <Form.Group>
                <Form.Control
                  className="fw-bold background-white mb-3"
                  type="text"
                  placeholder="Enter Content Name..."
                  id="name"
                  value={values.name}
                  onKeyPress={(e: any) => {
                    if (e.charCode === ENTER_KEY_CODE) {
                      e.preventDefault();
                      document.getElementById('name')?.blur();
                    }
                  }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isValid={touched.name && !errors.name}
                  isInvalid={!!errors.name && touched.name}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name ? errors.name : 'Name Required'}
                </Form.Control.Feedback>
              </Form.Group>

              <CardDetailsSignBadges
                selectedSigns={values.signs}
                userSigns={signs}
                onSubmitSelectedSigns={(signsVal, isLinkedVal) => {
                  // Don't include the display sign ids with the selected signs since it's for display only
                  setFieldValue('signs', signsVal);
                  setFieldValue('isLinked', isLinkedVal);
                }}
                files={selectedFile ? [selectedFile] : [contentItem?.file]}
              />
            </section>

            <section className="bg-white shadow-sm p-3 mt-2">
              <CardDetailsDateRange
                values={values}
                errors={errors}
                setFieldValue={setFieldValue}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </section>

            <section className="bg-white shadow-sm mt-2">
              <div className="bg-dark d-flex justify-content-center position-relative text-white">
                {(selectedFile)
                  ? (
                    <FileWithPreview
                      file={selectedFile}
                    />
                  )
                  : (contentItem.file &&
                    (<FileWithPreview
                      file={contentItem.file}
                    />
                    )
                  )
                }
                <div
                  style={{ position: 'absolute', bottom: -30, right: 30 }}
                  className="d-flex flex-column align-items-center"
                >
                  <Button
                    className="bg-white"
                    variant="outline-primary"
                    onClick={showSwapContentModal}
                  >
                    <FontAwesomeIcon icon="refresh" />
                  </Button>
                  <span style={{ fontSize: 10 }}>swap content</span>
                </div>
              </div>
              <div className="p-3">
                {(contentItem.file) && (
                  <p className="mb-2">
                    {contentItem.file.width} x {contentItem.file.height}
                  </p>
                )}
                <div>
                  {contentFile && (<FileDetailsBadges file={contentFile} />)}
                </div>
              </div>
            </section>
            { isImageFileType && (<section className="mt-2">
              <CardDetailsDuration
                durationInMilliseconds={durationInMilliseconds}
                error={errors.durationInMilliseconds}
                onChange={(newDurationInMilliseconds) => {
                  setFieldValue('durationInMilliseconds', newDurationInMilliseconds);
                }}
              />
            </section>)}

            <section className="mt-2">
              <CardDetailsRealtimeData
                values={values}
                contentFile={contentFile}
                setFieldValue={setFieldValue}
                errors={errors}
              />
            </section>

            <section className="mt-2">
              <CardDetailsFrequencyWeight
                item={values}
                setFieldValue={setFieldValue}
                disabledFrequencyWeight={!!playlistId}
              />
            </section>

            <section className="mt-2">
              <CardDetailsPlaybackActivity
                item={values}
                setFieldValue={setFieldValue}
              />
            </section>

            <div className="px-3 py-2 mt-3">
              <Button
                variant="danger"
                className="w-100"
                onClick={showConfirmDeleteModal}
              >
                Delete Content
              </Button>
            </div>

            <section
              className="mt-3 p-3 bg-white shadow-sm border-top sticky-bottom d-flex justify-content-end"
            >
              {errorList.length > 0 && (<Alert variant="danger" className="p-2 my-0 mx-2">{errorList[0]}<b>{errorList.length > 1 && ` +${errorList.length - 1} more`}</b></Alert>)}
              <Button
                variant="light"
                onClick={onCancel}
                disabled={updateOneContentIsLoading}
              >
                Cancel
              </Button>

              <Button
                className="ms-2"
                type="submit"
                variant="primary"
                disabled={updateOneContentIsLoading}
              >
                {(updateOneContentIsLoading)
                  ? (<FontAwesomeIcon
                    icon="circle-notch"
                    spin
                  />)
                  : 'Submit'
                }
              </Button>
            </section>
          </Form>
        );
      }}
    </Formik>
    {(contentItem.file) &&
    (<SwapContentModal
      isVisible={isSwapContentModalVisible}
      onHide={hideSwapContentModal}
      onSubmit={onSwapContent}
      initialSelectedFiles={[contentItem.file]}
      maxSelectedFilesCount={1}
    />)}
    <ConfirmDeleteModal
      show={isConfirmDeleteModalVisible}
      contentName={contentItem.name}
      onHide={hideConfirmDeleteModal}
      onConfirmDelete={(deleteAll) => onDeleteContents(deleteAll)}
      disableAllInstanceButton={!userHasAccessToSigns(signs, contentItem.signs)}
      disableAllInstanceButtonReason={!userHasAccessToSigns(signs, contentItem.signs)
        ? 'Cannot remove from all signs. Content is attached to signs that you do not have permissions to'
        : ''
      }
    />
  </>);
};

export default EditContent;
