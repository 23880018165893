import React, { forwardRef, useRef, useImperativeHandle, useEffect } from 'react';
import Form from 'react-bootstrap/esm/Form';
import { Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import _set from 'lodash/set';

import { today0000, today2359, durationFromNow2359  } from '@/utils/helpers';
import { Frequency } from '@/types/recurrenceRule';
import { IFileResponse, REALTIME_COUNTDOWN, REALTIME_COUNTUP } from '@/types/file';
import { ISignResponse } from '@/types/sign';
import FileWithPreview from '@/common/FileWithPreview/FileWithPreview';
import FileDetailsBadges from '@/common/FileDetailsBadges';
import { IContentForm } from '@/features/Schedule/types';
import { DEFAULT_FREQ, ENTER_KEY_CODE, DEFAULT_CONTENT_DURATION_IN_MILLISECONDS } from '@/features/Schedule/constants';
import { VALID_FILE_EXTENSIONS_IMAGE } from '@/utils/constants';

import CardDetailsDuration from '@/features/Schedule/components/CardDetailsDuration';
import CardDetailsRealtimeData from '@/features/Schedule/components/CardDetailsRealtimeData';
import CardDetailsFrequencyWeight from '@/features/Schedule/components/CardDetailsFrequencyWeight';
import CardDetailsPlaybackActivity from '@/features/Schedule/components/CardDetailsPlaybackActivity';
import CardDetailsSignBadges from '@/features/Schedule/components/CardDetailsSignBadges';
import CardDetailsDateRange from '@/features/Schedule/components/CardDetailsDateRange';
import { useReadSigns } from '@/hooks/sign';

import './AddContentForm.scss';
import { ContentRealtimeData } from '@/types/content';
import { fileRealtimeCounters } from '@/features/Schedule/utils';

interface AddContentFormProps {
  selectedFile: IFileResponse;
  selectedSign: ISignResponse | null;
  handleFormSubmit: (values: IContentForm) => void;
  onError: (error: string) => void;
}

export interface AddContentFormHandle {
  submit: () => void;
}

const AddContentForm = forwardRef<AddContentFormHandle, AddContentFormProps>(({
  selectedFile,
  selectedSign,
  onError,
  handleFormSubmit,
}, ref) => {
  const formRef = useRef<FormikProps<IContentForm>>(null);

  const {
    readSigns,
    signs,
  } = useReadSigns();

  useEffect(() => {
    document.getElementById('name')?.focus();

    readSigns();
  }, []);

  useImperativeHandle(ref, () => ({
    submit: () => {
      formRef.current?.handleSubmit();
    },
  }));

  const today12AM = today0000();
  const today1159PM = today2359();

  const oneMonthFromNow1159PM = durationFromNow2359({ month: 1 });

  const fileCounters = fileRealtimeCounters(selectedFile.realtimeTypes);

  const initializeRealtimeDataValues = () => {
    if (!fileCounters) return null;

    const realtimeDataCounters: ContentRealtimeData = { counters: {} };
    fileCounters.forEach((fileCounter) => {
      realtimeDataCounters.counters[fileCounter.id] = '';
    });
    return realtimeDataCounters;
  };

  const initialContentFormValues: IContentForm = {
    name: selectedFile.name,
    fileId: selectedFile.id,
    startDate: today12AM,
    endDate: oneMonthFromNow1159PM,
    durationInMilliseconds: selectedFile.durationInMilliseconds || DEFAULT_CONTENT_DURATION_IN_MILLISECONDS,
    frequencyWeight: 5,
    isPaused: false,
    realtimeData: initializeRealtimeDataValues(),
    signIds: selectedSign ? [selectedSign.id] : [],
    signs: selectedSign ? [selectedSign] : [],
    displaySigns: [],
    playlistIds: [],
    playlists: [],
    isLinked: true,
    recurrence: {
      rDate: [],
      exDate: [],
      exTime: [],
      freq: DEFAULT_FREQ as Frequency,
      byDay: [],
      byWeek: null,
      byMonth: null,
      byYear: null,
      startTime: today12AM,
      endTime: today1159PM,
    },
  };

  const validate = (values: IContentForm) => {
    const errors: {
      endDate?: string,
      startDate?: string,
      recurrence?: {
        exDate?: string,
        exTime?: string,
        startTime?: string,
        endTime?: string,
      },
      realtimeData?: {counters: Record<string, string>},
      durationInMilliseconds?: string,
    } = {};

    const addError = (errorKey: string, errorValue: string) => {
      _set(errors, errorKey, errorValue);
      onError(errorValue);
    };

    if (values.startDate > values.endDate) {
      addError('endDate', 'End date must be after start date');
      addError('startDate', 'Start date must be before end date');
    }

    if (values.recurrence.startTime.toMillis() >= values.recurrence.endTime.toMillis()) {
      if (!errors.recurrence) errors.recurrence = {};
      addError('recurrence.startTime', 'Daily Start must be before Daily End.');
      addError('recurrence.endTime', 'Daily End must be after Daily Start.');
    }

    if (values.recurrence.exTime && !!values.recurrence.exTime.find(([start, end]) => {
      return start > end;
    })) {
      if (!errors.recurrence) errors.recurrence = {};
      addError('recurrence.exTime', 'Start exclusion time must be before end exclusion time');
    }

    if (values.recurrence.exDate && !!values.recurrence.exDate.find(([start, end]) => {
      return start > end;
    })) {
      if (!errors.recurrence) errors.recurrence = {};
      addError('recurrence.exDate', 'Start exclusion date must be before end exclusion date');
    }

    // If user has counters in the template, make sure they've set the dates
    if (fileCounters) {
      fileCounters.forEach((fileCounter) => {
        const today = new Date();
        const fileCounterDateSet = values.realtimeData && new Date(values.realtimeData.counters[fileCounter.id]);
        const invalidDate = values.realtimeData && !values.realtimeData.counters[fileCounter.id];
        const invalidPastDate = fileCounter.direction === REALTIME_COUNTDOWN && fileCounterDateSet && fileCounterDateSet.getTime() < today.getTime();
        const invalidFutureDate = fileCounter.direction === REALTIME_COUNTUP && fileCounterDateSet && fileCounterDateSet.getTime() > today.getTime();

        if (invalidDate || invalidPastDate || invalidFutureDate) {
          if (!errors.realtimeData || !errors.realtimeData.counters) errors.realtimeData = { counters: {} };
          addError(`realtimeData.counters.${fileCounter.id}`, `Must set valid date for ${fileCounter.direction}.`);
        }
      });
    }

    if (typeof values.durationInMilliseconds === 'number' && values.durationInMilliseconds <= 0) {
      addError('durationInMilliseconds', 'Duration must be greater than 0');
      errors.durationInMilliseconds = 'Duration must be greater than 0';
    }

    return errors;
  };

  const schema = Yup.object().shape({
    name: Yup.string().required('Please enter content name'),
  });

  const isImageFileType = selectedFile
    && selectedFile.fileType
    && VALID_FILE_EXTENSIONS_IMAGE.includes(selectedFile.fileType.toLowerCase());

  return (
    <Formik
      initialValues={initialContentFormValues}
      onSubmit={handleFormSubmit}
      validationSchema={schema}
      validate={validate}
      innerRef={formRef}
    >
      {({
        values,
        touched,
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
      }: FormikProps<IContentForm>) => {
        const durationInMilliseconds = values.durationInMilliseconds || 0;

        return (
          <Form noValidate onSubmit={handleSubmit}>
            <section className="bg-white shadow-sm p-3">
              <Form.Group className="text-start">
                <Form.Control
                  className="fw-bold background-white mb-3"
                  type="text"
                  id="name"
                  placeholder="Enter Content Name..."
                  value={values.name}
                  onKeyPress={(e: any) => {
                    if (e.charCode === ENTER_KEY_CODE) {
                      e.preventDefault();
                      document.getElementById('name')?.blur();
                    }
                  }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isValid={touched.name && !errors.name}
                  isInvalid={!!errors.name && touched.name}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name ? errors.name : 'Name Required'}
                </Form.Control.Feedback>
              </Form.Group>
              {/* TODO: Need to set isLinked here */}
              <CardDetailsSignBadges
                selectedSigns={values.signs}
                userSigns={signs}
                files={[selectedFile]}
                onSubmitSelectedSigns={(selectedSigns, isLinked) => {
                  setFieldValue('signs', selectedSigns);
                  setFieldValue('isLinked', isLinked);
                }}
              />
            </section>

            <section className="bg-white shadow-sm p-3 mt-2">
              <CardDetailsDateRange
                values={values}
                errors={errors}
                setFieldValue={setFieldValue}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </section>

            <section className="bg-white shadow-sm mt-2">
              <div className="bg-dark d-flex justify-content-center text-white">
                <FileWithPreview
                  file={selectedFile}
                />
              </div>
              <div className="p-3">
                <p className="mb-0">
                  {selectedFile.width} x {selectedFile.height}
                </p>
                <div className="mt-2">
                  <FileDetailsBadges file={selectedFile} />
                </div>
              </div>
            </section>

            { isImageFileType && (<div className="mt-2">
              <CardDetailsDuration
                durationInMilliseconds={durationInMilliseconds}
                error={errors.durationInMilliseconds}
                onChange={(newDurationInMilliseconds) => {
                  setFieldValue('durationInMilliseconds', newDurationInMilliseconds);
                }}
              />
            </div>)}
            <div className="mt-2">
              <CardDetailsRealtimeData
                values={values}
                contentFile={selectedFile}
                setFieldValue={setFieldValue}
                errors={errors}
              />
            </div>
            <div className="mt-2">
              <CardDetailsFrequencyWeight
                item={null}
                setFieldValue={setFieldValue}
                disabledFrequencyWeight={false}
              />
            </div>
            <div className="my-2">
              <CardDetailsPlaybackActivity
                item={values}
                setFieldValue={setFieldValue}
              />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
});

export default AddContentForm;
