import React, { Fragment, useEffect } from 'react';
import { DateTime } from 'luxon';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/esm/Offcanvas';
import ListGroup from 'react-bootstrap/esm/ListGroup';
import ListGroupItem from 'react-bootstrap/esm/ListGroupItem';

import { useToggle } from '@/hooks';
import { useUpdateOnePlaylist } from '@/hooks/playlist';

import { IContentResponse } from '@/types/content';
import { IPlaylistFileToAdd, IPlaylistResponse, IPlaylistUpdateRequest } from '@/types/playlist';
import { ISignResponse } from '@/types/sign';
import { IFileResponse } from '@/types/file';

import Loader from '@/common/Loader';

import ContentListCard from '@/features/Schedule/components/ContentListCard';
import AddContentToPlaylistWizard from '@/features/Schedule/components/AddContentWizard/AddContentToPlaylistWizard';
import ReorderContentModal from '@/features/Schedule/components/ReorderContentModal';

import PendingPlaylistContentListCard from './PendingPlaylistContentListCard';

interface PlaylistContentsCardProps {
  sign: ISignResponse | null,
  playlistContents: IContentResponse[],
  pendingPlaylistContents: IPlaylistFileToAdd[],
  playlistDateRange: {start: DateTime, end: DateTime},
  addNewContent: (files: IPlaylistFileToAdd[]) => void,
  updateFileToAdd: (file: IPlaylistFileToAdd) => void,
  playlist?: IPlaylistResponse,
  refreshContentList?: () => void,
  userSigns: ISignResponse[],
}

const PlaylistContentsCard = ({
  sign,
  playlist,
  playlistContents,
  pendingPlaylistContents,
  updateFileToAdd,
  playlistDateRange,
  addNewContent,
  refreshContentList = () => null,
  userSigns,
}: PlaylistContentsCardProps) => {
  const routerNavigate = useNavigate();

  const { show: showContentListOptions, hide: hideContentListOptions, isVisible: isContentListOptionsVisible } = useToggle();
  const { show: showNewContentWizard, hide: hideNewContentWizard, isVisible: isNewContentWizardVisible } = useToggle();
  const { show: showReorderContentModal, hide: hideReorderContentModal, isVisible: isReorderContentModalVisible } = useToggle();

  const {
    updateOnePlaylistContents,
    updatedPlaylistContents,
    updateOnePlaylistContentsIsLoading,
  } = useUpdateOnePlaylist();

  const onAddNewContentSubmit = (files: IFileResponse[]) => {
    const playlistFileToAdd = files.map((file) => ({ file }));
    addNewContent(playlistFileToAdd);
    hideNewContentWizard();
  };

  const onEditContent = (clickedContent: IContentResponse) => {
    let contentRoute = `/assets/contents/${clickedContent.id}`;
    if (playlist && sign) contentRoute = `/schedule/signs/${sign.id}/playlists/${playlist.id}/contents/${clickedContent.id}`;
    if (sign) contentRoute = `/schedule/signs/${sign.id}/contents/${clickedContent.id}`;

    routerNavigate(contentRoute);
  };

  useEffect(() => {
    refreshContentList();
    hideReorderContentModal();
  }, [updatedPlaylistContents]);

  const saveReorderedContent = (contents: IContentResponse[]) => {
    if (!playlist) return;

    const playlistPutRequestData: IPlaylistUpdateRequest = {
      id: playlist.id,
      contentIds: contents.map((c) => c.id),
      signIds: playlist.signs.map((s) => s.id),
    };

    updateOnePlaylistContents(playlistPutRequestData);
  };

  return (
    <>
      <Card className="bg-white shadow-sm mt-2">
        <Card.Body>
          <Card.Title className="d-flex justify-content-between">
          Content

            {playlist ? (<Button
              className="text-dark"
              variant="link"
              onClick={showContentListOptions}
            >
              <FontAwesomeIcon icon={['fas', 'ellipsis-vertical']} />
            </Button>) : null}
          </Card.Title>
          <Button
            className="w-100 mb-3"
            variant="outline-success"
            onClick={showNewContentWizard}
          >
            <FontAwesomeIcon
              className="me-2"
              icon="plus"
            />
          Add
          </Button>
          {/* List of Contents in the Playlist that links to the content specifically */}
          {(playlistContents) ?
            playlistContents?.map((content: IContentResponse) => {
              return (
                <ContentListCard
                  key={content.id}
                  contentItem={content}
                  refreshSchedule={refreshContentList}
                  parentPlaylist={playlist}
                  selectedSign={sign}
                  onEditClick={onEditContent}
                  userSigns={userSigns}
                />
              );
            },
            )
            : (
              <Loader size="2x" />
            )}

          {(pendingPlaylistContents) ?
            pendingPlaylistContents.map((playlistContent: IPlaylistFileToAdd) => {
              return (
                <Fragment key={playlistContent.file.id}>
                  <PendingPlaylistContentListCard
                    playlistFile={playlistContent}
                    startDate={playlistDateRange.start}
                    endDate={playlistDateRange.end}
                    onChangePlaylistFile={updateFileToAdd}
                  />
                </Fragment>
              );
            })
            : null
          }
        </Card.Body>
      </Card>

      {/* Content List Options */}
      <Offcanvas
        className="list-offcanvas"
        show={isContentListOptionsVisible}
        onHide={hideContentListOptions}
        placement="bottom"
      >
        <Offcanvas.Header>
          <Offcanvas.Title>Content List Options</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ListGroup>
            <ListGroupItem
              action
              onClick={() => {
                hideContentListOptions();
                showReorderContentModal();
              }}
            >
            Reorder Content
            </ListGroupItem>
          </ListGroup>
        </Offcanvas.Body>
      </Offcanvas>

      {/* Add New Content */}
      <AddContentToPlaylistWizard
        isVisible={isNewContentWizardVisible}
        onHide={hideNewContentWizard}
        selectedSign={sign}
        onSubmit={onAddNewContentSubmit}
      />

      {/* Reorder Content Modal */}
      <ReorderContentModal
        show={isReorderContentModalVisible}
        onHide={hideReorderContentModal}
        contents={playlistContents}
        onUpdate={saveReorderedContent}
        isSubmitting={updateOnePlaylistContentsIsLoading}
        selectedSign={sign}
      />
    </>
  );
};

export default PlaylistContentsCard;
