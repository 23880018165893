import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import Alert from 'react-bootstrap/esm/Alert';
import Button from 'react-bootstrap/esm/Button';
import Modal from 'react-bootstrap/esm/Modal';
import _uniq from 'lodash/uniq';

import SelectFileForm from '@/features/Files/SelectFileForm/SelectFileForm';
import { IFileResponse } from '@/types/file';
import { ISignResponse } from '@/types/sign';
import { IContentForm } from '@/features/Schedule/types';

import AddContentForm, { AddContentFormHandle } from './components/AddContentForm';

interface AddContentWizardProps {
  isVisible: boolean;
  onHide: () => void;
  selectedSign: ISignResponse | null;
  onSubmit: (files: IFileResponse[], formValues?: IContentForm) => void;
  inPlaylistCreate?: boolean;
  isLoading?: boolean;
  maxSelectedFilesCount?: number;
}

const AddContentWizard = ({
  isVisible,
  onHide,
  isLoading,
  selectedSign,
  inPlaylistCreate,
  onSubmit,
  maxSelectedFilesCount,
}: AddContentWizardProps) => {
  const [step, setStep] = useState(1);
  const [selectedFiles, setSelectedFiles] = useState<IFileResponse[] | null>(null);
  const [errorList, setErrorList] = useState<string[]>([]);
  const addFormRef = useRef<AddContentFormHandle>(null);

  useEffect(() => {
    // reset state when modal is opened
    if (isVisible === true) {
      setErrorList([]);
      setSelectedFiles(null);
      setStep(1);
    }
  }, [isVisible]);

  const previousButtonText = step === 1
    ? 'Cancel'
    : 'Previous';
  const nextButtonText = step === 2 || inPlaylistCreate
    ? 'Submit'
    : 'Next';
  const stepTitleDictionary: Record<number, string> = {
    1: 'Step 1: Choose File',
    2: 'Step 2: Configure',
  };
  const stepTitle = stepTitleDictionary[step];

  const onClose = () => {
    setErrorList([]);
    onHide();
  };

  const onClickPreviousButton = () => {
    switch (step) {
      case 1:
        onClose();
        break;
      case 2:
        setErrorList([]);
        setStep(step - 1);
        break;
      default:

        // do nothing
    }
  };

  const onClickNextButton = () => {
    switch (step) {
      case 1:
        // show toast error if file not selected
        if (!selectedFiles || !selectedFiles.length) {
          toast(
            'Must select file before continuing',
            {
              type: 'error',
            },
          );
          return;
        }

        if (!inPlaylistCreate) {
          setStep(step + 1);
        } else {
          onSubmit(selectedFiles);
        }

        break;
      case 2:
        addFormRef?.current?.submit();
        break;
      default:

        // do nothing
    }
  };

  const onSelectFiles = (files: IFileResponse[]) => {
    if (maxSelectedFilesCount) {
      // only select the most recent number of files
      const limitedSelectedFiles = files.slice(-maxSelectedFilesCount);
      return setSelectedFiles(limitedSelectedFiles);
    }

    return setSelectedFiles(files);
  };

  const renderBody = (currentStep: number) => {
    if (currentStep === 1) {
      return (<div className="p-3 bg-white">
        <SelectFileForm
          selectedFiles={selectedFiles}
          onSelectFiles={onSelectFiles}
        />
      </div>);
    }

    if (currentStep === 2 && selectedFiles) {
      return (<AddContentForm
        ref={addFormRef}
        selectedFile={selectedFiles[0]}
        selectedSign={selectedSign}
        onError={(error) => setErrorList(_uniq([...errorList, error]))}
        handleFormSubmit={(formValues) => onSubmit(selectedFiles, formValues)}
      />);
    }

    return <></>;
  };

  return (
    <Modal
      show={isVisible}
      onHide={onClose}
      fullscreen="md-down"
      backdrop="static"
      size="lg"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <div>
          <h1 className="h4 mb-0">Add Content</h1>
          <h2 className="h6 mb-0">
            {stepTitle}
          </h2>
        </div>
      </Modal.Header>
      <Modal.Body className="p-0 grey-modal-body">
        {renderBody(step)}
      </Modal.Body>
      <Modal.Footer className="sticky-modal-footer">
        {errorList.length > 0 && (<Alert variant="danger" className="p-2 my-0 mx-2">{errorList[0]}<b>{errorList.length > 1 && ` +${errorList.length - 1} more`}</b></Alert>)}
        <Button
          variant="light"
          onClick={onClickPreviousButton}
          disabled={isLoading}
        >
          {previousButtonText}
        </Button>
        {(selectedFiles && inPlaylistCreate) && <span className="fw-bold mx-3">{selectedFiles.length} Selected</span>}
        <Button
          className="ms-2"
          type="submit"
          variant="primary"
          onClick={onClickNextButton}
        >
          {nextButtonText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddContentWizard;
